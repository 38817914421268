import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import SEO from '../../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Projects" mdxType="SEO" />
    <div className="relative">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "2000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d2453b54dda2b73649f13e7dd7cc0fd3/07827/tractor.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.66015625%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFJS1Qqagr/xAAaEAADAAMBAAAAAAAAAAAAAAABAgMAETIx/9oACAEBAAEFApKunUYsCQPa8y4//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AYx//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BV//EABoQAAICAwAAAAAAAAAAAAAAAAECABEgMpH/2gAIAQEABj8CLMTKXs2w/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUYH/2gAIAQEAAT8hXoBQVdRQFlrbv2Ww840yzi+2Apk//9oADAMBAAIAAwAAABDcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQMBAT8QB1s3/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EHA//8QAHBABAAMBAAMBAAAAAAAAAAAAAQARITFRYXHB/9oACAEBAAE/ELplApX6831MBojbFwH5FWCxgk3IUnGfSG3zCEA14T//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tractor",
              "title": "tractor",
              "src": "/static/d2453b54dda2b73649f13e7dd7cc0fd3/7ef33/tractor.jpg",
              "srcSet": ["/static/d2453b54dda2b73649f13e7dd7cc0fd3/c635c/tractor.jpg 500w", "/static/d2453b54dda2b73649f13e7dd7cc0fd3/7e3f6/tractor.jpg 1000w", "/static/d2453b54dda2b73649f13e7dd7cc0fd3/7ef33/tractor.jpg 2000w", "/static/d2453b54dda2b73649f13e7dd7cc0fd3/07827/tractor.jpg 2048w"],
              "sizes": "(max-width: 2000px) 100vw, 2000px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
  <div className="absolute w-full h-full top-0 left-0 justify-center flex-col items-center flex">
    <h1 className="text-4xl text-white lg:text-8xl md:text-6xl sm:text-4xl font-extrabold text-accent-light">
      Projects
    </h1>
  </div>
    </div>
    <h1 {...{
      "id": "gawler-caravan-park-accommodation-development"
    }}>{`Gawler Caravan Park Accommodation Development`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b04edf45acbbf25ab83eead37506c6e8/2972e/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdCOiMUDBP/EABkQAQEAAwEAAAAAAAAAAAAAAAIBAAMRBP/aAAgBAQABBQK+jhqb1a+soS4jZBGT/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAbEAABBQEBAAAAAAAAAAAAAAAAAQIRMVESIf/aAAgBAQAGPwKpcdK7zCSjUIah/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERITFBUWGR/9oACAEBAAE/IcQmiMDZ2yUU3mLFQh/gyPFKZtn/2gAMAwEAAgADAAAAEAvf/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EA2P/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EFyP/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBYZH/2gAIAQEAAT8QDQhYb9rW+R7l1dBq/OMl5h0a6IFHQUI6Eqg58od8Zej1QVuf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gawler group",
            "title": "gawler group",
            "src": "/static/b04edf45acbbf25ab83eead37506c6e8/7ef33/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg",
            "srcSet": ["/static/b04edf45acbbf25ab83eead37506c6e8/c635c/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg 500w", "/static/b04edf45acbbf25ab83eead37506c6e8/7e3f6/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg 1000w", "/static/b04edf45acbbf25ab83eead37506c6e8/7ef33/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg 2000w", "/static/b04edf45acbbf25ab83eead37506c6e8/2972e/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg 2592w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Cabins both 25.9sqm (7.2m by 3.6m) and 51.8sqm (7.2m by 7.2m)`}</p>
    <p><strong parentName="p">{`Rooms`}</strong>{`: Bedroom, Bathroom (toilet & shower), kitchen, living room`}</p>
    <p>{`The seven cabins we provided for Gawler Caravan Park are perfect examples of our minimalist module design and construction in action. Built from locally-sourced materials, these cabins provide an idyllic stay for travellers on the road. All cabins are equipped with solar hot water heating, reverse cycle air conditioning, underfloor heating and cooling.`}</p>
    <h1 {...{
      "id": "fluid-solar-house"
    }}>{`Fluid Solar House`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d86dfa8e333e1d83025158f9c70ee3ef/2972e/long-view-fluid-solar-house.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAcGE51Liof/EABoQAAIDAQEAAAAAAAAAAAAAAAABAgMSESH/2gAIAQEAAQUCySpQ6z3u5jbb/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BlL//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAQIS/9oACAECAQE/AZu2/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQEiExMv/aAAgBAQAGPwIVY2ztmWz/xAAZEAEBAQADAAAAAAAAAAAAAAABABExQXH/2gAIAQEAAT8hSSuhR5sHJVJCd8p9v//aAAwDAQACAAMAAAAQEC//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QkNP/xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAECAQE/EGWojD//xAAbEAEBAQEAAwEAAAAAAAAAAAABEQAhMVGR0f/aAAgBAQABPxBGJcNRQIuMn7qaonkc8X6aVOVnTma++nP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Fluid Solar House",
            "title": "Fluid Solar House",
            "src": "/static/d86dfa8e333e1d83025158f9c70ee3ef/7ef33/long-view-fluid-solar-house.jpg",
            "srcSet": ["/static/d86dfa8e333e1d83025158f9c70ee3ef/c635c/long-view-fluid-solar-house.jpg 500w", "/static/d86dfa8e333e1d83025158f9c70ee3ef/7e3f6/long-view-fluid-solar-house.jpg 1000w", "/static/d86dfa8e333e1d83025158f9c70ee3ef/7ef33/long-view-fluid-solar-house.jpg 2000w", "/static/d86dfa8e333e1d83025158f9c70ee3ef/2972e/long-view-fluid-solar-house.jpg 2592w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The world’s first off-grid office building, now in its third year of operation in Elizabeth Vale, South Australia. The space functions as Fluid Solar Thermal HQ and a shared office collective, with spaces available for lease.`}</p>
    <p><strong parentName="p">{`Energy system`}</strong>{`: 150-kilowatt (kW) rooftop concentrating solar thermal collector array; a 2200 kWh thermal storage system with a radiant hydronic heating and cooling using stored thermal energy; latent energy storage capacity exceeding 10,000 kWh; two Tesla car charging points.`}</p>
    <p><strong parentName="p">{`Size`}</strong>{`: 3000sqm`}</p>
    <hr></hr>
    <p>{`Contact us about your next project`}</p>
    <p>{`127 Haydown Rd, Elizabeth Vale SA 5112, Australia`}</p>
    <p>{`enquiries@fluidsolarthermal.com`}</p>
    <p>{`+618 8440 7788
+61 414 450 215`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      